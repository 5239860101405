export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

export const SET_USER_DATA_SUCCESS = "SET_USER_DATA_SUCCESS";
export const SET_USER_DATA_ERROR = "SET_USER_DATA_ERROR";

export const GET_GAME_DATA_SUCCESS = "GET_GAME_DATA_SUCCESS";
export const GET_GAME_DATA_ERROR = "GET_GAME_DATA_ERROR";

export const GET_QUESTION_DATA_SUCCESS = "GET_QUESTION_DATA_SUCCESS";
export const GET_QUESTION_DATA_ERROR = "GET_QUESTION_DATA_ERROR";

export const GET_TRIVIA_DATA_SUCCESS = "GET_TRIVIA_DATA_SUCCESS";
export const GET_TRIVIA_DATA_ERROR = "GET_TRIVIA_DATA_ERROR";

export const SUBMIT_TRIVIA_ANSWER_SUCCESS = "SUBMIT_TRIVIA_ANSWER_SUCCESS";
export const SUBMIT_TRIVIA_ANSWER_ERROR = "SUBMIT_TRIVIA_ANSWER_ERROR";

export const SUBMIT_TRIVIA_VOTE_SUCCESS = "SUBMIT_TRIVIA_VOTE_SUCCESS";
export const SUBMIT_TRIVIA_VOTE_ERROR = "SUBMIT_TRIVIA_VOTE_ERROR";

export const SET_CLIENT_DATA_SUCCESS = "SET_CLIENT_DATA_SUCCESS";
export const SET_CLIENT_DATA_ERROR = "SET_CLIENT_DATA_ERROR";

export const GET_DATA_BY_RELOAD = "GET_DATA_BY_RELOAD";

export const READY_FOR_NEXT_SUCCESS = "READY_FOR_NEXT_SUCCESS";
export const READY_FOR_NEXT_ERROR = "READY_FOR_NEXT_ERROR";

//Teletrations Game types

export const SUBMIT_PROMPT_SUCCESS = "SUBMIT_PROMPT_SUCCESS";
export const SUBMIT_PROMPT_ERROR = "SUBMIT_PROMPT_ERROR";

export const SUBMIT_DRAWING_SUCCESS = "SUBMIT_DRAWING_SUCCESS";
export const SUBMIT_DRAWING_ERROR = "SUBMIT_DRAWING_ERROR";

export const SUBMIT_CAPTION_SUCCESS = "SUBMIT_CAPTION_SUCCESS";
export const SUBMIT_CAPTION_ERROR = "SUBMIT_CAPTION_ERROR";
